import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/var/www/kecman.dev/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SourceList = makeShortcode("SourceList");
const Flex = makeShortcode("Flex");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const Card = makeShortcode("Card");
const Text = makeShortcode("Text");
const Heading = makeShortcode("Heading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Posts`}</h1>
    <SourceList filter="posts" mdxType="SourceList">
  {posts => {
        if (posts.length > 0) {
          return <Flex sx={{
            flexWrap: "wrap",
            ml: theme => `-${theme.space[2]}px`,
            mr: theme => `-${theme.space[2]}px`
          }} mdxType="Flex">
      {posts.filter(edge => !edge.node.frontmatter.isPrivate).sort((a, b) => new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date)).splice(0, 3).map((edge, index) => {
              const {
                frontmatter: {
                  title,
                  date,
                  featuredImage,
                  url,
                  misc
                },
                fields: {
                  slug
                }
              } = edge.node;
              console.log(featuredImage);
              return <Box key={index} sx={{
                mb: 3,
                maxWidth: ["100%", "50%", "33.333%"],
                width: ["100%", "50%", "33.333%"]
              }} mdxType="Box">
            <Link href={slug} sx={{
                  textDecoration: 'none'
                }} mdxType="Link">
              <Card sx={{
                    ml: 2,
                    mr: 2
                  }} mdxType="Card"> 
                
                <Box sx={{
                      p: 3
                    }} mdxType="Box">
                <Text sx={{
                        fontSize: 0,
                        color: 'muted'
                      }} mdxType="Text">{format(new Date(date), 'd-MMM-u')}</Text>
                <Heading variant="styles.h4" sx={{
                        color: 'text'
                      }} mdxType="Heading">{title}</Heading>
                <Text sx={{
                        textDecoration: 'underline'
                      }} mdxType="Text">View project /> </Text>
                </Box>
               
              </Card>
            </Link>
          </Box>;
            })}
    </Flex>;
        } else {
          return <Text sx={{
            fontSize: 0,
            color: 'muted',
            marginBottom: 3
          }} mdxType="Text">No posts found.</Text>;
        }
      }}
    </SourceList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      